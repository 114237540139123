import React, { useState, setState, state, useEffect, useRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DonorType from '../components/DonorType';
import SelectDonationHistory from '../components/SelectDonationHistory';
import CheckedYesBox from '../components/CheckedYesBox';
import AddKeyMessage from '../components/AddKeyMessage';
import { BrowserRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faL, faUpload } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { Axios } from 'axios';

import { isCompositeComponentWithType } from 'react-dom/test-utils';
import useForm from '../useForm';
// import DonationHistoryCalendar from './DonationHistoryCalendar';
import DatePickerCalendar from '../components/DatePickerCalendar';
import CheckedOtherCommentBox from '../components/CheckedOtherCommentBox';
import ReCaptcha from '../config/ReCaptcha';
import UploadFiles from '../components/UploadFiles';
import AdditionalCommentBox from '../components/AdditionalCommentBox';
import LocationCenter from '../components/LocationCenter';
import LoginForm from '../components/LoginForm';
import { CalendarCSL } from '../container/calendar/CalendarCSL';
import CalendarIcon from '../assets/images/icons/calendar-icon.svg'
import CampaignRun from '../container/campaignRun';
import KeyMessage from '../container/keyMessage';
import API from '../utils/request'
import { INTAKE_FORM, UPLOAD_FILE } from '../api/URLs';

import "../styles/intakeForm.css"
import StakeHolder from '../components/stakeholder/stakeHolder';
import CampaignDetails from '../components/campaignDetails/campaignDetails';
import Audience from '../components/audience/audience';
import Content from '../components/Content';
import Design from '../components/Design';
import CampaignMandatories from '../components/CampaignMandatories';
import CampaignKPIs from '../components/CampaignKPIs';
import { connect } from 'react-redux';
import { formatDate, formatDateAPI, formatDateAPI2 } from '../functions/format/dateFormat';
import LoadingOverlay from '../container/loading/LoadingOverlay';
import { validationSubmitForm } from '../functions/validation';
import MessageModalMain from '../container/modal/MessageModalMain';
import StakeHolderV2 from '../components/stakeholder/stakeHolderV2';
import CampaignDetailsV2 from '../components/campaignDetails/campaignDetailsV2';
import AudienceV2 from '../components/audience/audienceV2';
import ContentV2 from '../components/ContentV2';
import DesignV2 from '../components/DesignV2';
import CampaignMandatoriesV2 from '../components/CampaignMandatoriesV2';
import CampaignKPIsV2 from '../components/CampaignKPIsV2';
import handleScrollTo from '../utils/scrollToService';

const IntakeFormV2 = (props) => {
    const { user } = props;
    
    const [campaignObject, setCampaignObject] = useState({
        reportRequest: {
            desiredMetrics: [],
            comment: ''
        },
        campaignStatus: 'daily',
        startDate: '',
        endDate: '',
        additionalComment: '',

        requestType: "technical-ticket",//technical-ticket, campaign-requests
        summaryTitle: "",
        summaryIssue: "",
        dateDiscovered: "",//2023-03-25
        dateResolution: "",
        adhocEvergreen: null,
        // {
        //     "title": "Adhoc",//Evergreen, Adhoc
        //     "type": "adhoc",//evergreen, adhoc
        //     "isCheck": "yes"
        // },
        contentRequest: "",////United States / Puerto Rico / Both
        campaignHaveOffer: null, //{ "type": "Yes", "isCheck": "yes" }
        "nameApprover": "",//if yes inser name approve, if no show disclaimer
        languageApply: [
            // {
            //     "type": "US - English",
            //     "isCheck": "yes"
            // },
            // {
            //     "type": "US - Spanish",
            //     "isCheck": "yes"
            // },
            // {
            //     "type": "Puerto Rico - Spanish",
            //     "isCheck": "yes"
            // }
        ],
        additionalDocumentation: [],
        isVersion: "v2"

    });

    const messageModalRef = useRef('rememberMe');
    const [loading, setLoading] = useState(false)
    const [disabledButton, setDisabledButton] = useState(true)
    const [isSubmit, setisSubmit] = useState(false)
    //Stakeholder Value
    const [emailTest, setEmailTest] = useState()
    //Campaign Details Value
    const [campaignTitle, setCampaignTitle] = useState()
    const [campaignGoal, setCampaginGoal] = useState()
    const [dateFrom, setDateFrom] = useState()
    const [dateTo, setDateTo] = useState()
    //Audience Value
    const [donorType, setDonorType] = useState([])
    const [selectedDonorTypes, setSelectedDonorTypes] = useState([]);
    const [donorTypeOtherComment, setDonorTypeOtherComment] = useState()
    const [controlGroup, setcontrolGroup] = useState("")
    const [initialLabel, setInitialLabel] = useState()
    const [secondLabel, setSecondLabel] = useState()
    const [thirdLabel, setThirdLabel] = useState()

    //Donation History
    const [historyOption, setHistoryOption] = useState()
    const [donationHistory, setDonationHistory] = useState({
        "noDonation": "no",
        "donationAmount": "no",
        "donationDate": "no",
        "otherHistory": "no"
    })

    const [donorHistory, setDonorHistory] = useState([])
    const [checkDonation, setCheckDonation] = useState()
    const [checkAmount, setCheckAmount] = useState()

    const [donationAmount, setDonationAmount] = useState([])
    const [dateAmount, setDateAmount] = useState([])

    const [valueAmount, setValueAmount] = useState([])
    const [keyAmount, setKeyAmount] = useState()
    const [keyDate, setKeyDate] = useState()
    const [checkDate, setCheckDate] = useState()
    const [donationDate, setDonationDate] = useState()
    const [checkOther, setCheckOther] = useState()
    const [otherComment, setOtherComment] = useState()

    const [checkAllLocation, setCheckAllLocation] = useState('no')
    const [selectedState, setSelectedState] = useState([])
    const [selectedCities, setSelectedCities] = useState([])
    const [selectedCenter, setSelectedCenter] = useState([])
    const [locationCheckOther, setLocationCheckOther] = useState()
    const [locationOtherComment, setLocationOtherComment] = useState()

    const [campaignRunmore, setCampaignRunmore] = useState([])

    const [donorLocation, setDonorLocation] = useState([])
    const [audienceExceptions, setAudienceExceptions] = useState()
    const [totalApproximate, setTotatApproximate] = useState()
    const [campaignRun, setCampaignRun] = useState([])
    const [retargetConditions, setRetargetConditions] = useState([])
    //Content Value
    const [numCommunications, setNumCommunications] = useState();
    const [addKeyMessage, setAddKeyMessage] = useState([{
        keyMessage: '',
        keyLanguage: '',
        channel: [
            { "type": "email", "isCheck": "no" },
            { "type": "app", "isCheck": "no" },
            { "type": "sms", "isCheck": "no" },
        ],
        errMesage: '',
        errChannel: ''
    }])
    const [keyMessage, setKeyMessage] = useState()
    // const [channels, setChannels] = useState([])
    const [personalization, setPersonalization] = useState([{
        dynamicContents: [
            { "type": "First Name", "isCheck": "no" },
            { "type": "Last Donation Center", "isCheck": "no" },
            { "type": "Other", "isCheck": "no" }
        ],
        additionalText: ''
    }])
    //Design Value
    const [styleGuide, setStyleGuide] = useState()
    const [newContent, setNewContent] = useState()
    const [otherNewContent, setOtherNewContent] = useState()
    const [existingContent, setExistingContent] = useState()
    const [specifyCampaign, setSpecifyCampaign] = useState()
    const [contentApplied, setContentApplied] = useState()
    const [hasCampaign, setHasCampaign] = useState()
    const [listPeople, setListPeople] = useState()
    const [contentDesignAsset, setContentDesignAsset] = useState([
        {
            keyLanguage: '',
            files: []
        }
    ])
    const [designAssetLeverage, setdesignAssetLeverage] = useState([
        {
            keyLanguage: '',
            files: []
        }
    ])
    const [audienceExceptionAsset, setaudienceExceptionAsset] = useState([])
    const [additionalDocumentationAsset, setadditionalDocumentationAsset] = useState([])
    const [designOther, setdesignOther] = useState({
        "other": "no",
        "additionalText": ""
    })
    //Campaign Mandatories
    const [campaignOffer, setCampaignOffer] = useState()
    const [legalDislaimer, setLegalDislaimer] = useState()
    const [campaignKPI, setCampaginKPI] = useState()
    const [specifyLegalDeclaimer, setSpecifyLegalDeclaimer] = useState()
    const [haveApproved, setHaveApproved] = useState({
        "title": "Enter name of approver*",
        "type": "",//Yes
        "isCheck": "", //yes
        "insertName": "",
        "isConfirm": false, // true== checked, false=no check
    })
    const [needLegalDeclaimer , setNeedLegalDeclaimer] = useState("")
    //Campaign KPIs
    // const [campaignObject, setCampaignObject] = useState({
    //     reportRequest: {
    //         desiredMetrics: [],
    //         comment: ''
    //     },
    //     campaignStatus: '',
    //     startDate: '',
    //     endDate: '',
    //     additionalComment: '',
    // });
    const [metricsRequired, setMetricsRequired] = useState()
    const [updateStatus, setUpdateStatus] = useState()
    const [additionalComments, setAdditionalComments] = useState()
    const [captchaToken, setCaptchaToken] = useState()

    const [toActive, setToActive] = useState(false)
    const [fromActive, setFromActive] = useState(false)
    const [locationAllPR, setLocationAllPR] = useState("")
    const [locationAll, setLocationAll] = useState("")


    const [formData, setFormData] = useState({
        title: '',
        goal: '',
        target_audience: [],
        channels: [],
        approved_legal: [],
        approved_people: '',
        approved_budget: '',
        required_disclaimer: '',
        success_metrics: '',
        status_updates: ''
    })

    const [formError, setFormError] = useState({});
    const onChangeHandler = (event) => {
        if (event.target.name === 'channels') {
            let copy = { ...formData }
            if (event.target.checked) {
                copy.channels.push(event.target.value)
            } else {
                copy.channels = copy.channels.filter(el => el !== event.target.value)
            }
            setFormData(copy)

        } else {
            setFormData(() => ({
                ...formData,
                [event.target.name]: event.target.value
            }))
        }
    }

    const validateKeyMessage = () => {
        let countValid = 0 ;
        addKeyMessage.map(it => {
            if(!it.keyMessage){
                countValid ++;
                it.errMesage = "Mesage required!";
            }
            let i = 0;
            it.channel.map(ch=> {
                if(ch.isCheck === 'no'){
                    i++;
                }
            })
            if(i === it.channel.length){
                countValid++;
                it.errChannel = "Select one required!";
            }
        });
        if(countValid > 0){
            return true;
        }
        return false;
    }
    
    const validateForm = () => {
        let error = {}
        if (formData.title === '') {
            error.title = 'Field required!'
        }
        if (formData.goal === '') {
            error.goal = 'Field required!'
        }
        if (formData.target_audience === '') {
            error.target_audience = 'Select one required!'
        }
        if (formData.channels.length < 1) {
            error.channels = 'Select one required!'
        }
        if (formData.approved_legal.length < 1) {
            error.approved_legal = 'Select one required!'
        }
        if (formData.approved_people === '') {
            error.approved_people = 'Field required!'
        }
        if (formData.approved_budget.length < 1) {
            error.approved_budget = 'Select one required!'
        }
        if (formData.required_disclaimer.length < 1) {
            error.required_disclaimer = 'Select one required!'
        }
        if (formData.success_metrics === '') {
            error.success_metrics = 'Field required!'
        }
        if (formData.status_updates.length < 1) {
            error.status_updates = 'Field required!'
        }

        setFormError({ ...error })

        return Object.keys(error).length < 1;
    }

    const [isChecked, setIsChecked] = useState(false);
    const [error, setError] = useState({});
    const [dateRangeError, setDateRangeError] = useState({})

    const [token, setToken] = useState(null);

    const checkAudienceType = () => {
        let typeArr = []
        let audienceTypesArr = [];
        if (initialLabel) {
            typeArr.push(initialLabel)
        }
        if (secondLabel) {
            typeArr.push(secondLabel)
        }
        if (thirdLabel) {
            typeArr.push(thirdLabel)
        }
        typeArr.forEach(it => {
            switch (it) {
                case 'Donor Type':
                    audienceTypesArr.push({
                        "inputType": "donor-type",
                        "donorType": donorType,
                        "additionalText": donorType.find(it => it === 'Other') ? donorTypeOtherComment : ''
                    })
                    break;
                case 'Donation History':
                    audienceTypesArr.push({
                        "inputType": "donation-history",
                        "noDonation": donationHistory.noDonation,
                        "donationAmount": {
                            "enable": donationHistory.donationAmount,
                            "key": keyAmount ? keyAmount : "",
                            "value": donationHistory.donationAmount === 'yes' ? donationAmount : '',
                        },
                        "donationDate": {
                            "enable": donationHistory.donationDate,
                            "key": keyDate ? keyDate : "",
                            "value": donationHistory.donationDate ? dateAmount : [null, null]
                        },
                        "other": donationHistory.otherHistory,
                        "additionalText": donationHistory.otherHistory === 'yes' ? otherComment : '',
                    })
                    break;
                case 'Location':
                    audienceTypesArr.push({
                        "inputType": "location",
                        "allCenter": checkAllLocation,
                        "typeCenter": locationAll,
                        "typePrCenter":locationAllPR,
                        "states": checkAllLocation === 'no' ? selectedState : '',
                        "city": checkAllLocation === 'no' ? selectedCities : '',
                        "centers": checkAllLocation === 'no' ? selectedCenter : '',
                        "other": locationCheckOther ? locationCheckOther : 'no',
                        "additionalText": locationCheckOther === 'yes' ? locationOtherComment : ''
                    })
                    break;
            }
        });
        return audienceTypesArr;
    }

    const [ validAud, setValidAud ] = useState({
        initialLabel : false,
        secondLabel : false,
        thirdLabel : false
    })
    const validateAudience = async () => {
        let valid = {
            initialLabel : false,
            secondLabel : false,
            thirdLabel : false
        }
        let countValid = 0 ;
        let typeArr = []
        if (initialLabel) {
            typeArr.push(initialLabel)
        }
        if (secondLabel) {
            typeArr.push(secondLabel)
        }
        if (thirdLabel) {
            typeArr.push(thirdLabel)
        }
        if(typeArr.length === 0) {
            valid.initialLabel = true
            setValidAud(valid)
            return true
        }
        typeArr.forEach((it, index) => {
            switch (it) {
                case 'Donor Type':
                    if(!donorType.length) {
                        countValid ++
                        if(index === 0 ) {
                            valid.initialLabel = true
                        } else if(index == 1) {
                            valid.secondLabel = true
                        } else {
                            valid.thirdLabel = true
                        }
                    }
                    break;
                case 'Donation History':
                    if(donationHistory.donationAmount === 'yes') {
                        if(keyAmount === 'less-then' || keyAmount === 'greater-then') {
                            if(donationAmount[0] === null || donationAmount[0] === '' ) {
                                countValid ++
                                if(index === 0 ) {
                                    valid.initialLabel = true
                                } else if(index == 1) {
                                    valid.secondLabel = true
                                } else {
                                    valid.thirdLabel = true
                                }
                            }
                        } else {
                            if(donationAmount[0] === null || donationAmount[1] === null || donationAmount[0] === ''  || donationAmount[1] === ''  ) {
                                countValid ++
                                if(index === 0 ) {
                                    valid.initialLabel = true
                                } else if(index == 1) {
                                    valid.secondLabel = true
                                } else {
                                    valid.thirdLabel = true
                                }
                            }
                        }
                    }
                    if(donationHistory.donationDate == 'yes'){
                        if(keyDate === 'before' || keyDate === 'after') {
                            if(dateAmount[0] === null  || dateAmount[0] === '' ) {
                                countValid ++
                                if(index === 0 ) {
                                    valid.initialLabel = true
                                } else if(index == 1) {
                                    valid.secondLabel = true
                                } else {
                                    valid.thirdLabel = true
                                }
                            }
                        } else {
                            if(dateAmount[0] === null || dateAmount[1] === null || dateAmount[0] === '' || dateAmount[1] === '' ) {
                                countValid ++
                                if(index === 0 ) {
                                    valid.initialLabel = true
                                } else if(index == 1) {
                                    valid.secondLabel = true
                                } else {
                                    valid.thirdLabel = true
                                }
                            }
                        }
                    }
                    if(donationHistory.noDonation != 'yes' &&  donationHistory.donationAmount != 'yes' && donationHistory.donationDate != 'yes' ) {
                        countValid ++
                        if(index === 0 ) {
                            valid.initialLabel = true
                        } else if(index == 1) {
                            valid.secondLabel = true
                        } else {
                            valid.thirdLabel = true
                        }
                    }
                    break;
                case 'Location':
                    if( checkAllLocation != 'yes' && selectedState.length === 0 && selectedCities.length === 0 && selectedCenter.length === 0 ) {
                        countValid ++
                        if(index === 0 ) {
                            valid.initialLabel = true
                        } else if(index == 1) {
                            valid.secondLabel = true
                        } else {
                            valid.thirdLabel = true
                        }
                    }
                    break;
            }
        });
        
        setValidAud(valid)

        if(countValid > 0){
            return true;
        }
        return false;
    }

    useEffect(() => {
        validateAudience()
    }, [donorType])

    useEffect(() => {
        validateAudience()
    }, [donationHistory])

    useEffect(() => {
        validateAudience()    
    }, [checkAllLocation,
        selectedState,
        selectedCities,
        selectedCenter,
        locationCheckOther,
        locationOtherComment])


    const languageApplyChecked = (type) => {
        let typeCheck = campaignObject && campaignObject.languageApply && campaignObject.languageApply.filter(it => it.isCheck === "yes")
        return typeCheck && typeCheck.length > 0 ? false : true
    }

    const handleSubmitForm = async (e, isSave) => {
        e.preventDefault()
        setisSubmit(true);
        let campaignStatus = campaignObject.campaignStatus
        let camOffer = campaignOffer ? (campaignOffer.isCheck === 'yes' ? campaignOffer : 'no') : 'no'
        setError(validationSubmitForm(campaignTitle, campaignGoal, listPeople, campaignOffer , legalDislaimer, campaignStatus, initialLabel, specifyLegalDeclaimer, campaignObject.requestType))

        if (!campaignObject.requestType) {
            setError({
                ...error,
                requestType: "Fields is Required*"
            })
            handleScrollTo("requestType")
            return false
        }
        if (campaignObject.requestType === "technical-ticket") {
            if (!campaignObject.summaryTitle) {
                handleScrollTo("summaryTitle")
                setError({
                    ...error,
                    summaryTitle: "Fields is Required*"
                })
                return false
            }
        } else {
            if (!campaignObject.adhocEvergreen || campaignObject.adhocEvergreen && campaignObject.adhocEvergreen.isCheck !== "yes") {
                handleScrollTo("adhocEvergreen")
                setError({
                    ...error,
                    adhocEvergreen: "Fields is Required*"
                })
                return false
            }


            if (!campaignTitle) {
                handleScrollTo("campaignTitle")
                setError({
                    ...error,
                    campaignTitle: "Fields is Required*"
                })
                return false
            }
        }

        if (isSave !== 'draft') {

            if (!campaignObject.requestType) {
                setError({
                    ...error,
                    requestType: "Fields is Required*"
                })
                handleScrollTo("requestType")
                return false
            }
            if (campaignObject.requestType === "technical-ticket") {
                if (!campaignObject.summaryTitle) {
                    handleScrollTo("summaryTitle")
                    setError({
                        ...error,
                        summaryTitle: "Fields is Required*"
                    })
                    return false
                }
                if (!campaignObject.summaryIssue) {
                    handleScrollTo("summaryIssue")
                    setError({
                        ...error,
                        summaryIssue: "Fields is Required*"
                    })
                    return false
                }
                if (!campaignObject.dateDiscovered || !campaignObject.dateResolution) {
                    handleScrollTo("dateDiscoveredDateResolution")
                    setError({
                        ...error,
                        "dateDiscovered": "Date of issue discovered is required*",
                        "dateResolution": "Due date for desired resolution is required*"
                    })
                    return false
                }
            } else {

                if (!campaignObject.adhocEvergreen || campaignObject.adhocEvergreen && campaignObject.adhocEvergreen.isCheck !== "yes") {
                    handleScrollTo("adhocEvergreen")
                    setError({
                        ...error,
                        adhocEvergreen: "Fields is Required*"
                    })
                    return false
                }


                if (!campaignTitle) {
                    handleScrollTo("campaignTitle")
                    setError({
                        ...error,
                        campaignTitle: "Fields is Required*"
                    })
                    return false
                }


                if (validateKeyMessage()) {
                    handleScrollTo("AppendKeyMessageV2")
                    return false;
                }

                if ((!dateFrom || !dateTo)) {
                    handleScrollTo("dateRangeCampaignDuration")
                    setDateRangeError({
                        ...dateRangeError,
                        "dateRange": "Date Range is Required*",
                        "campaignDuration": "Campaign Duration is Required*",

                    })
                    return false;
                }


                let validAud = await validateAudience()
                if (validAud) {
                    handleScrollTo("targetAudience")
                    return false;
                }

                if (!controlGroup) {
                    handleScrollTo("controlGroup")
                    return false;
                }

                if(controlGroup === "yes" && !totalApproximate) {
                    handleScrollTo("totalApproximate")
                    return false;
                }


                if (languageApplyChecked()) {
                    handleScrollTo("languageApply")
                    setError({
                        ...error,
                        languageApply: "Fields is Required*"
                    })
                    return false
                }


                if (!campaignObject.campaignHaveOffer) {
                    handleScrollTo("campaignHaveOffer")
                    setError({
                        ...error,
                        campaignHaveOffer: "Fields is Required*"
                    })
                    return false
                }

                if(!needLegalDeclaimer) {
                    handleScrollTo("needLegalDeclaimer")
                    setError({
                        ...error,
                        needLegalDeclaimer: "Fields is Required*"
                    })
                    return false
                }

                if(needLegalDeclaimer === "yes" ) {
                if (campaignObject.campaignHaveOffer && campaignObject.campaignHaveOffer.isCheck === "yes" ) {
                    if(!campaignOffer || campaignOffer.length === 0) {
                        setError({
                            ...error,
                            campaignOffer: "Fields is Required*"
                        })
                        handleScrollTo("haveApproved")
                        return false
                    }
                    if(campaignOffer && campaignOffer.length > 0) {
                        if (campaignOffer && campaignOffer.find(it => it.isCheck === "yes")?.isCheck === 'yes') {
                            if(!campaignObject.nameApprover) {
                                handleScrollTo("nameApprover")
                                setError({
                                    ...error,
                                    nameApprover: "Fields is Required*"
                                })
                                return false
                            }
                        }
                    }
                }
                if(!haveApproved.isCheck) {
                    handleScrollTo("campaignKpis")
                    setError({
                        ...error,
                        listPeople: "Fields is Required*"
                    })
                    return false
                }
                if (haveApproved.isCheck === "yes" && !haveApproved.insertName) {
                    handleScrollTo("haveApproved")
                    setError({
                        ...error,
                        listPeople: "Fields is Required*"
                    })
                    return false
                }
                }
            }

        }

        let reportRequest = campaignObject.reportRequest
        reportRequest.comment = reportRequest.desiredMetrics.find(it => it.isCheck === 'yes' && it.type === 'Other') ? reportRequest.comment : ''
        
        var param = {

            requestType: campaignObject.requestType,//technical-ticket, campaign-requests
            summaryTitle: campaignObject.summaryTitle,
            summaryIssue: campaignObject.summaryIssue,
            dateDiscovered: campaignObject.dateDiscovered ? formatDateAPI2(campaignObject.dateDiscovered) : '',//2023-03-25
            dateResolution: campaignObject.dateResolution ? formatDateAPI2(campaignObject.dateResolution) : "",
            adhocEvergreen: campaignObject.adhocEvergreen ? campaignObject.adhocEvergreen : null,
            contentRequest:campaignObject.contentRequest,//United State / Puerto Rico / Both
            languageApply: campaignObject.languageApply ? campaignObject.languageApply : null,
            additionalDocumentation: additionalDocumentationAsset ? additionalDocumentationAsset : null,

            "emailTesting": emailTest ? emailTest : "",//remove
            "title": campaignTitle ? campaignTitle : "",
            "goal": campaignGoal ? campaignGoal : "",//remove
            "startDuration": dateFrom ? formatDateAPI2(dateFrom) : "",
            "endDuration": dateTo ? formatDateAPI2(dateTo) : "",
            "targetAudience": await checkAudienceType(),
            "audienceException": audienceExceptions ? audienceExceptions : "",
            "audienceExceptionAsset": audienceExceptionAsset,
            "controlGroup": controlGroup,
            "totalApproximate": totalApproximate ? totalApproximate.replace(/,/g, '') : "",
            "campaignRunmore": campaignRunmore,//remove
            "numberCommunications": 10,
            "addKeyMessage": addKeyMessage.map(it => ({
                keyMessage: it.keyMessage, 
                keyLanguage: it.keyLanguage ? it.keyLanguage : '', 
                channel: it.channel.map(ch => ({ "type": ch.type, "isCheck": ch.isCheck }))
            })),
            "retargetConditions": retargetConditions.length > 0 ? retargetConditions : '',//remove
            "personalization": personalization,//remove
            "styleGuide": styleGuide,//remove
            "contentDesignAsset": contentDesignAsset.map(it => ( {//remove
                keyLanguage: it.keyLanguage,
                files: it.files
            })),
            "designOther": designOther,//remove
            "designAssetLeverage": designAssetLeverage.map(it => ( {//remove
                keyLanguage: it.keyLanguage,
                files: it.files
            })),
            "specifyCampaignAsset": specifyCampaign,//remove
            "contentApply": contentApplied,
            "campaignIdea": hasCampaign,
            "peopleApprove": hasCampaign ? hasCampaign.find(it => it.isCheck === 'yes') ? listPeople : '' : '',
            "campaignHaveOffer": campaignObject.campaignHaveOffer,
            "nameApprover": campaignObject.nameApprover,
            "campaignOffer": campaignOffer,
            "legalDeclaimer": legalDislaimer,
            "haveApproved": haveApproved,
            "needLegalDeclaimer": needLegalDeclaimer,
            "campaignKpi": campaignKPI,//remove
            "reportRequest": reportRequest,//remove
            "campaignStatus": campaignObject.campaignStatus,//remove
            "startDate": campaignObject.startDate,//remove
            "endDate": campaignObject.endDate,//endDate
            "status": "pending",

            "additionalComment": campaignObject.additionalComment,
            "journeyGoalConfiguration": "",
            "journeyAudienceEntryCriteria": "",
            "dataPointsHealthCloud": "",
            "reEnterJourneyRequirements": "",
            "sFMCFolder": "",
            "sMSPushActivitiesSettings": "",
            "adminAdditionalComment": "",

            // "gReCaptchaResponse": token

            isVersion: "v2",
            isSave: isSave ? isSave : ''
        }

        setLoading(true)

        API.post(INTAKE_FORM, param)
            .then((res) => {
                setLoading(false)
                console.log(res);
                if (res.status === 1) {
                    messageModalRef.current.showSuccess(res.message, () => {
                        window.location.href = '/'
                    });
                } else {
                    messageModalRef.current.showError(res.message);
                }
            })
            .catch((err) => {
                setLoading(false)
                if(typeof err.message === 'string'){
                    messageModalRef.current.showError(err.message);
                }
                console.log(err);
            })
    }

    const handleUploadFile = (fileName, filebase64, type, setNewContentLoading, setExistingContentLoading, i) => {
        var param = {
            "filename": fileName,
            "fileAsset": filebase64
        }

        if(type === 'contentDesignAsset'){
            setNewContentLoading(true)
            contentDesignAsset[i].newContentLoading = true
            setContentDesignAsset([...contentDesignAsset])
        }
        if(type === 'designAssetLeverage'){
            setExistingContentLoading(true)
            designAssetLeverage[i].existingContentLoading = true
            setdesignAssetLeverage([...designAssetLeverage])
        }
        if(type === 'audienceExceptionAsset'){
            setExistingContentLoading(true)
        }
        API.post(UPLOAD_FILE, param)
            .then((res) => {
                setNewContentLoading(false)
                setExistingContentLoading(false)
                if (res.result) {
                    if (type === 'contentDesignAsset') {
                        contentDesignAsset[i].newContentLoading = false
                        contentDesignAsset[i].files.push({"url": res.url})
                        setContentDesignAsset([...contentDesignAsset])
                    } else if (type === 'audienceExceptionAsset') {
                        audienceExceptionAsset.push({"url": res.url})
                        setaudienceExceptionAsset([...audienceExceptionAsset])
                    }  else if (type === 'additionalDocumentationAsset') {
                        additionalDocumentationAsset.push({"url": res.url})
                        setadditionalDocumentationAsset([...additionalDocumentationAsset])
                    } 
                    else {
                        designAssetLeverage[i].existingContentLoading = false
                        designAssetLeverage[i].files.push({"url": res.url})
                        setdesignAssetLeverage([...designAssetLeverage])
                    }
                }else if(res.result === false) {
                    messageModalRef.current.showWarning(res?.message);
                }
            })
            .catch((err) => {
                setNewContentLoading(false)
                setExistingContentLoading(false)
                console.log(err);
                if(type === 'contentDesignAsset') {
                    contentDesignAsset[i].newContentLoading = false
                    setContentDesignAsset([...contentDesignAsset])
                }
                if(type === 'designAssetLeverage'){
                    designAssetLeverage[i].existingContentLoading = false
                    setdesignAssetLeverage([...designAssetLeverage])
                }
            })
    }

    return (
        <div className="main-form d-flex flex-column align-items-center">
            {loading ? <LoadingOverlay /> : null}
            <MessageModalMain textCentered ref={messageModalRef} />
            <form className='form-group' 
            // onSubmit={handleSubmitForm}
            >
                <div className='d-flex flex-column align-items-center justify-content-center' style={{ marginBottom: '50px' }}>
                    <h2 className='size30700' style={{ marginBottom: '8px' }}>Marketing Campaign Intake Form</h2>
                    {/* <p className='size16400 mb-0'>Please complete the form to request a marketing campaign journey.</p>
                    <p className='size14520 mb-0'>Please be advised: Digital campaigns take approximately 2 weeks from approval to deployment.</p> */}
                </div>
                <div>
                    <StakeHolderV2
                        user={user}
                        setEmailTest={setEmailTest}
                        emailTest={emailTest}
                    />
                    <div className="csl-red-line"></div>
                    <CampaignDetailsV2
                        validateFields={error}
                        setCampaignTitle={setCampaignTitle}
                        campaignTitle={campaignTitle}
                        setDateFrom={setDateFrom}
                        dateFrom={dateFrom}
                        setDateTo={setDateTo}
                        dateTo={dateTo}
                        campaignGoal={campaignGoal}
                        setCampaginGoal={setCampaginGoal}
                        dateRangeError={dateRangeError ? dateRangeError.campaignDuration : null}
                        dateRangeErrorDis={error ? error.dateDiscovered : null}
                        dateRangeErrorEnd={error ? error.dateResolution : null}
                        addKeyMessage={addKeyMessage}
                        setAddKeyMessage={setAddKeyMessage}
                        submits={{isSubmit, setisSubmit}}
                        campaignObject={campaignObject} setCampaignObject={setCampaignObject}
                        messageModalRef={messageModalRef}
                    />
                    {campaignObject.requestType === "campaign-requests" ? <div className="csl-red-line"></div> : ""}
                    {campaignObject.requestType === "campaign-requests" ? <AudienceV2
                        submits={{isSubmit, setisSubmit}}
                        validateFields={error}
                        validAuds={{validAud, setValidAud, validateAudience}}
                        setDonorType={setDonorType}
                        donorType={donorType}
                        setDonorTypeOtherComment={setDonorTypeOtherComment}
                        donorTypeOtherComment={donorTypeOtherComment}
                        locationAll={locationAll} 
                        setLocationAll={setLocationAll}
                        locationAllPR={locationAllPR} 
                        setLocationAllPR={setLocationAllPR}
                        controlGroup={controlGroup}
                        setcontrolGroup={setcontrolGroup}

                        //case 2
                        keyAmount={keyAmount}
                        setKeyAmount={setKeyAmount}
                        setKeyDate={setKeyDate}
                        keyDate={keyDate}
                        setOtherComment={setOtherComment}
                        otherComment={otherComment}
                        setDonationHistory={setDonationHistory}
                        donationHistory={donationHistory}
                        donationAmount={donationAmount}
                        dateAmount={dateAmount}

                        setDonationAmount={setDonationAmount}
                        setDateAmount={setDateAmount}

                        //case 3
                        setCheckAllLocation={setCheckAllLocation}
                        checkAllLocation={checkAllLocation}
                        setSelectedState={setSelectedState}
                        selectedState={selectedState}
                        setSelectedCities={setSelectedCities}
                        selectedCities={selectedCities}
                        setSelectedCenter={setSelectedCenter}
                        selectedCenter={selectedCenter}
                        setLocationCheckOther={setLocationCheckOther}
                        locationCheckOther={locationCheckOther}
                        setLocationOtherComment={setLocationOtherComment}
                        locationOtherComment={locationOtherComment}

                        selectedTypes={{
                            selectedDonorTypes, setSelectedDonorTypes,
                            initialLabel, setInitialLabel, secondLabel, setSecondLabel,
                            thirdLabel, setThirdLabel
                        }}
                        audienceExceptions={audienceExceptions}
                        setAudienceExceptions={setAudienceExceptions}
                        setTotatApproximate={setTotatApproximate}
                        totalApproximate={totalApproximate}
                        retargetConditions={retargetConditions}
                        setRetargetConditions={setRetargetConditions}
                        campaignRunmore={campaignRunmore}
                        setCampaignRunmore={setCampaignRunmore}
                        uploadeFile={{
                            handleUploadFile,
                            audienceExceptionAsset, setaudienceExceptionAsset,
                        }}
                    /> : ""}
                    {campaignObject.requestType === "campaign-requests" ? <div className="csl-red-line"></div> : ""}
                    {campaignObject.requestType === "campaign-requests" ? <ContentV2
                        validateFields={error}
                        addKeyMessage={addKeyMessage}
                        setAddKeyMessage={setAddKeyMessage}
                        personalization={personalization}
                        setPersonalization={setPersonalization}
                        submits={{isSubmit, setisSubmit}}
                        campaignObject={campaignObject} setCampaignObject={setCampaignObject}
                    /> : ""}
                    {/* {campaignObject.requestType === "campaign-requests" ? <div className="csl-red-line"></div> : ""} */}
                    {/* {campaignObject.requestType === "campaign-requests" ? <DesignV2
                        validateFields={error}
                        setStyleGuide={setStyleGuide}
                        styleGuide={styleGuide}
                        setSpecifyCampaign={setSpecifyCampaign}
                        specifyCampaign={specifyCampaign}
                        setContentApplied={setContentApplied}
                        contentApplied={contentApplied}
                        setHasCampaign={setHasCampaign}
                        hasCampaign={hasCampaign}
                        setListPeople={setListPeople}
                        listPeople={listPeople}
                        uploadeFile={{
                            handleUploadFile, contentDesignAsset,
                            setContentDesignAsset, designAssetLeverage,
                            setdesignAssetLeverage, designOther, setdesignOther
                        }}
                    /> : ""} */}
                    {campaignObject.requestType === "campaign-requests" ? <div className="csl-red-line"></div> : ""}
                    {campaignObject.requestType === "campaign-requests" ? <CampaignMandatoriesV2
                        validateFields={error}
                        campaignKPI={campaignKPI}
                        setCampaginKPI={setCampaginKPI}
                        setCampaignOffer={setCampaignOffer}
                        campaignOffer={campaignOffer}
                        legalDislaimer={legalDislaimer}
                        setLegalDislaimer={setLegalDislaimer}
                        needLegalDeclaimer={needLegalDeclaimer} 
                        setNeedLegalDeclaimer={setNeedLegalDeclaimer}
                        specifyLegalDeclaimer={specifyLegalDeclaimer}
                        setSpecifyLegalDeclaimer={setSpecifyLegalDeclaimer}
                        haveApproved={haveApproved} setHaveApproved={setHaveApproved}
                        isSubmit={isSubmit}
                        campaignObject={campaignObject} setCampaignObject={setCampaignObject}
                    /> : ""}
                    {campaignObject.requestType === "campaign-requests" ? <div className="csl-red-line"></div> : ""}
                    {/* {
                        hasCampaign ?
                            hasCampaign.map((item) =>

                                item.isCheck === "yes" ? <>
                                    <CampaignMandatories
                                        validateFields={error}
                                        campaignKPI={campaignKPI}
                                        setCampaginKPI={setCampaginKPI}
                                        setCampaignOffer={setCampaignOffer}
                                        campaignOffer={campaignOffer}
                                        legalDislaimer={legalDislaimer}
                                        setLegalDislaimer={setLegalDislaimer}
                                        specifyLegalDeclaimer={specifyLegalDeclaimer}
                                        setSpecifyLegalDeclaimer={setSpecifyLegalDeclaimer}
                                    />
                                    <div className="csl-red-line"></div>
                                </> : null)
                            : null
                    } */}
                    <CampaignKPIsV2
                        campaign={{ campaignObject, setCampaignObject }}
                        dateRangeError={dateRangeError ? dateRangeError.dateRange : null}
                        validateFields={error}
                        uploadeFile={{
                            handleUploadFile,
                            additionalDocumentationAsset, setadditionalDocumentationAsset,
                        }}
                    />
                </div>
                {/* <div className="required">
                    <ReCaptcha
                        setToken={setToken}
                        setDisabledButton={setDisabledButton}
                    />
                    <p className="required-fields">Required Fields *</p>
                </div> */}
                <div style={{ marginTop: "40px", display:'flex', justifyContent:'space-between' }}>
                    <button
                        className="button_auth button_body"
                        onClick={(e) => handleSubmitForm(e)}
                        style={{
                            margin:"0px" 
                        }}
                        // disabled={disabledButton}
                    >
                        <p
                            style={{
                                fontWeight: "800",
                                fontSize: "16px",
                                marginBottom: "0",
                                color: "white",
                            }}
                        >
                            Submit
                        </p>
                    </button>
                    <button
                        className="button_auth button_body"
                        onClick={(e) => handleSubmitForm(e, "draft")}
                        style={{
                            width:"30%",
                            margin:"0px"
                        }}
                    >
                        <p
                            style={{
                                fontWeight: "800",
                                fontSize: "16px",
                                marginBottom: "0",
                                color: "white",
                            }}
                        >
                            Save For Later
                        </p>
                    </button>
                </div>
            </form>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({ dispatch })

const mapStateToProps = state => ({
    user: state.authReducer.user,
    token: state.authReducer.token
})

export default (connect(mapStateToProps, mapDispatchToProps)(IntakeFormV2));